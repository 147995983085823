<template>
  <body-layout :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button type="danger" class="mr-3" v-if="selectedRowKeys.length" @click="batchDel">删除</a-button>
      <a-button
        type="danger"
        class="mr-3"
        v-if="selectedRowKeys.length"
        :disabled="selectionRows.some(item => item.status == 2)"
        @click="reject"
        >拒绝</a-button
      >
      <a-button
        type="primary"
        v-if="selectedRowKeys.length"
        :disabled="selectionRows.some(item => item.status == 1)"
        @click="finish"
        >同意</a-button
      >
      <!--  <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="queryParam.searchText" placeholder="请输入人员昵称" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="padding-top:10px;padding-bottom:20px;">
      <a-radio-group v-model="radioValue">
        <a-radio value="1">全部</a-radio>
        <a-radio value="2">已处理</a-radio>
        <a-radio value="3">未处理</a-radio>
      </a-radio-group>
    </div>
    <v-table
      ref="newTable"
      :queryParamProp="queryParam"
      :tableType="'approveTable'"
      :selectedRowKeysprop="selectedRowKeys"
      :url="url"
      @openModal="openModal"
      @reUserInfo='reUserInfo'
      @openRemark="openRemark"
      :columns="columns"
      :isMaterial="true"
      @setRowkeys="setRowkeys"
      @changeStarus="changeStarus"
      @changeTime="changeTime"
      :isPreview="false"
      @closeRemark="handleCancel"
    />
    <a-modal v-model="visible" @cancel="handleCancel" @ok="handleNoWorkOk" title="请输入拒绝理由" :width="500">
      <a-textarea :autosize="true" v-model="remark" placeholder="请输入拒绝理由"></a-textarea>
    </a-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { simpleDebounce } from '@/utils/util'
import vTable from '@/components/v-table/vTable'
import { checkPermission } from '@/utils/hasPermission'
import { queryPermissionsByUser } from '@/api/api'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
export default {
  mixins: [JeecgListMixin],
  components: { vTable },
  data() {
    return {
      visible: false,
      remark: '',
      rejectRows: [],
      positionList: [],
      rowId: '',
      radioValue: '3',
      curRow: [],
      btnLoading: false,
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      openData: {},
      userInfo: {
        roles: []
      },
      url: {
        list: '/user/leave/page',
        delete: '/user/leave/',
        deleteBatch: '/user/leave/'
      },
      color: '',
      applyReason: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'approveSort',
          scopedSlots: { customRender: 'approveSort' },
          width: 50,
          checked: true,
          isNum: false
        },
        {
          title: '人员昵称',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: 180,
          checked: true,
          isNum: false
        },
        {
          title: '申请日期',
          dataIndex: 'applyTime',
          checked: true,
          isNum: false
        },
        {
          title: '请假理由',
          dataIndex: 'applyReason',
          checked: true,
          isNum: false
        },
        {
          title: '状态',
          dataIndex: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' },
          width: 120,
          checked: true,
          isNum: false
        },
        {
          title: '处理人',
          dataIndex: 'approveUser',
          scopedSlots: { customRender: 'approveUser' },
          width: 180,
          checked: true,
          isNum: false
        },
        {
          title: '处理时间',
          dataIndex: 'processTime',
          width: 180,
          checked: true,
          isNum: false
        },
        {
          title: '操作',
          dataIndex: 'approveAction',
          scopedSlots: { customRender: 'approveAction' },
          width: 150,
          checked: true,
          isNum: false
        }
      ],
      queryParam: {
        status: [],
        searchType: 2,
        current: 1,
        pageSize: -1,
        totalResult: 0,
        searchText: '',
        searchTime: []
      }
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == '1') {
        this.queryParam.searchType = 0
      } else if (this.radioValue == '2') {
        this.queryParam.searchType = 1
      } else if (this.radioValue == '3') {
        this.queryParam.searchType = 2
      }
      this.queryParam.status = []
      this.queryParam.searchTime = []
      this.$nextTick(() => {
        this.$refs.newTable.checkedDeadlineKeys = []
        this.$refs.newTable.defaultOpenKeys = []
        this.$refs.newTable.checkedAppKeys = []
        this.$refs.newTable.loadData()
      })
    }
  },
  methods: {
    openRemark(rows) {
      this.visible = true
      this.rejectRows = rows
      this.remark = ''
    },
    handleNoWorkOk() {
      this.$refs.newTable.approveReject(this.rejectRows, this.remark)
    },
    changeStarus(checkedAppKeys) {
      this.queryParam.status = checkedAppKeys
      this.$refs.newTable.loadData()
    },
    changeTime(keys) {
      this.queryParam.searchTime = keys.filter(item => item.length > 7)
      this.$refs.newTable.loadData()
    },
    batchDel: function() {
      console.log('JeecgListMixin batchDel')
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return
      } else {
        if (this.selectionRows.some(item => !item.status)) {
          this.$message.warning('记录未处理！')
          return
        }
        var ids = this.selectedRowKeys.join(',')
        var that = this

        let url = that.url.deleteBatch
        // 增加作品管理的产品id
        if (this.productionId) {
          url = `${url}/${this.productionId}`
        } else if (this.platformId) {
          url = `${url}/${this.platformId}`
        }

        this.$confirm({
          title: '确认删除',
          content: '是否删除选中数据?',
          onOk: function() {
            that.loading = true
            deleteAction(url + ids)
              .then(res => {
                if (res.success) {
                  that.$message.success(res.message || res.msg ? '删除成功' : '')
                  that.selectionRows.forEach(async row => {
                    await postAction('/workSchedule/', {
                      startDate: row.applyStartTimeStr,
                      endDate: row.applyEndTimeStr,
                      type: row.type ? null : row.type,
                      userIds: [row.applyUserId]
                    })
                  })
                  if (that.$refs.newTable) {
                    that.$refs.newTable.loadData()
                  } else {
                    that.loadData()
                  }
                  that.onClearSelected()
                  that.$emit('loadData')
                  queryPermissionsByUser().then(response => {
                    Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
                    that.$store.commit('SET_INFO', response.data.userInfo)
                  })
                } else {
                  that.$message.warning(res.message || res.msg)
                }
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
      }
    },
    getTableData() {
      this.$refs.newTable.loadData()
    },
    openModal() {},
    setRowkeys(records) {
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    handleCancel() {
      this.visible = false
      this.remark = ''
      this.rejectRows = []
      queryPermissionsByUser().then(response => {
        Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
        this.$store.commit('SET_INFO', response.data.userInfo)
      })
    },
    play(url) {
      if (this.$refs.videoModal) this.$refs.videoModal.open(url)
    },
    openDetail: simpleDebounce(async function(row) {
      this.openDetailSim(row)
    }, 500),
    async openDetailSim(row) {
      this.isAdd = true
      if (this.mytasksDetialPath) {
        this.$router.push({
          path: `${this.mytasksDetialPath}/${row.taskId}/${row.productionId}/${row.chapterId}?${
            this.fromProduction ? 'fromProduction=1' : ''
          }&record=${encodeURIComponent(
            JSON.stringify({ platformId: row.platformId, productionName: row.productionName })
          )}`
        })
      } else if (this.detailPath) {
        this.$router.push({ path: `${this.detailPath}/${row.id}` })
      } else {
        const res = await getAction('/questionAnswer/detail?id=' + row.questionAnswer.id)
        if (res.code == 200) {
          this.curRow = res.data
          this.rowId = row.questionAnswer.id
        }
        /* this.curRow = JSON.parse(JSON.stringify(row)) //防止在drawer中修改内容会影响列表 */
        console.log({ 'openDetail this.curRow': this.curRow })
        if (this.curRow.cardList) {
          this.curRow.cardList.forEach(item => {
            if (item.dataList == null) {
              item.dataList = [{ valueRequired: 'NO' }]
            }
          })
        }
        this.drawerShow = true
        this.isNewForm = true
      }
    },
    getShowHome(time) {
      let text = '未展示'
      if (time) {
        //发布时间是否超过当前时间
        if (new Date(time).getTime() < new Date().getTime()) {
          text = '首页展示中'
        }
      }
      return text
    },
    openRejectModel(record) {
      this.openData = record
    },
    async finish() {
      this.$refs.newTable.approveFinish(this.selectionRows)
    },
    async reject() {
      let that = this
      this.$confirm({
        title: '确认驳回?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          that.$refs.newTable.approveReject(that.selectionRows, '')
          queryPermissionsByUser().then(response => {
            Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
            that.$store.commit('SET_INFO', response.data.userInfo)
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    reUserInfo() {
      let that = this
      queryPermissionsByUser().then(response => {
        Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
        that.$store.commit('SET_INFO', response.data.userInfo)
      })
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.indexOf(value) > -1)
      } else {
        this.searchUsers = []
      }
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
    },
    async stop(record) {
      const res = await putAction('productionOnline/unpublish/' + record.productionOnline.id)
      if (res.code == 200) {
        this.$message.success('已停用')
        this.loadData()
        queryPermissionsByUser().then(response => {
          Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
          this.$store.commit('SET_INFO', response.data.userInfo)
        })
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async send(record) {
      const res = await putAction('productionOnline/publish', { id: record.productionOnline.id })
      if (res.code == 200) {
        this.$message.success('发布成功')
        this.loadData()
        queryPermissionsByUser().then(response => {
          Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
          this.$store.commit('SET_INFO', response.data.userInfo)
        })
      } else {
        this.$message.error(res.msg || res.message)
      }
    }
  },
  created() {
    this.$bus.$on('openHelpMsg', record => {
      this.openDetail(record)
    })
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}
/deep/ .ant-card-body {
  min-height: 400px !important;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .list-content {
  p {
    margin-bottom: 0 !important;
  }
}
.file-span {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
